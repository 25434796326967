import React, { useEffect, useState } from "react"
import { Icon } from "@iconify/react";
import * as styles from './header.module.css';
import firebase from 'gatsby-plugin-firebase';
import { Link, navigate } from "gatsby";
import logo from "../../static/images/keyweUpdatedlogo.png"
import AvatarImage from "./Avatar/avatarImage";
import { calenderResponse } from "../state/calenderPopUp";
import { useSelector, useDispatch } from "react-redux";
import { notificationList, profileView, getNotificationCount } from "./Api/ListingApi";
import { profileUpdate, userDetailInfo } from "./Api/formApi"
import "stream-chat-react/dist/css/index.css";
import { StreamChat } from "stream-chat";
import { getMessageCount, chattingUserData, notificationCount, } from "../state/dashboardViewAction";
import InactiveProfilePopup from "../components/popup/inactiveProfilePopup"
import { userResponse } from "../state/userSlice"
import { leadsId } from "../state/userSlice";


export default function Sidebar({ userType }) {
  // const [notification, setNotification] = useState([]);
  // const [pagecount, setpagecount] = useState(0);
  // const [selectedPage, setSelectedPage] = useState(0);
  const [activeRoute, setActiveRoute] = useState("");
  const message_count = useSelector(state => state.dashboard.messageCount)
  const notification_count = useSelector(state => state.dashboard.note_count)
  // const chatUser = useSelector(state => state.dashboard.chat_disconnect);
  var currentPage = 1;
  const [menu, setMenu] = useState([])
  const dispatch = useDispatch()
  const [showProfile, setShowProfile] = useState(false);

  const userDetails = useSelector(state => state.user.userDetail);
  const handleLogoutFirebase = async () => {
    await firebase
      .auth()
      .signOut()
    localStorage.removeItem("persist:root");
    localStorage.removeItem("userInfo");
    localStorage.removeItem('userId')
    navigate("/");
    dispatch(calenderResponse([]))
    dispatch(leadsId(""))
  }
  const showProfilePopup = () => {
    setShowProfile(!showProfile);
  }
  useEffect(() => {
    async function fetchData() {
      await getNotification();
    } fetchData()
  }, []);
  const getNotification = async (searchs) => {
    let userId = localStorage.getItem('userId')
    let params = {
      id: userId
    }

    await getNotificationCount(userId).then((res) => {
      // setNotification(res.data.data)
      dispatch(notificationCount(res.data.Count))
      // setpagecount(res.data.Paging.total_page);
      // setSelectedPage(res.data.Paging.current_page - 1);
    }).catch((error) => {

    })
  }
  const [client, setClient] = useState(null);
  // const [messageCount, setMessageCount] = useState(null)
  const apikey = process.env.STREAM_CHAT_APIKEY;


  const hanldeImage = (img) => {
    let data = img !== null && img !== undefined && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }
  useEffect(() => {

    if (userType === "agent") {

      var menuArr = [
        {
          id: 1,
          name: "Buyers",
          path: "/buyers",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/buyeractive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/buyerIcon.png" />,
        },
        {
          id: 2,
          name: "Sellers",
          path: "/sellers",
          activeimage: <img className="navimage" alt="sellerIcon" src="/images/selleractive.png" />,
          image: <img className="navimage" alt="sellerIcon" src="/images/sellerIcon.png" />
        },
        // {
        //   id: 3,
        //   name: "My Referrals",
        //   path: "/referralFee",
        //   activeimage: <img className="navimage" alt="referralIcon" src="/images/referralfeeicon1.png" />,
        //   image: <img className="navimage" alt="referralIcon" src="/images/referralfeeicon.png" />
        // },
        {
          id: 3,
          name: "My Leads",
          path: "/myleads",
          activeimage: <img className="navimage" alt="referralIcon" src="/images/referralfeeicon1.png" />,
          image: <img className="navimage" alt="referralIcon" src="/images/referralfeeicon.png" />
        },

        {
          id: 4,
          name: "My Listing",
          path: "/myList",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/ownedpropertyactive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/ownedproperty.png" />
        },
        {
          id: 5,
          name: 'Notifications',
          path: "/notifications",
          activeimage: <img className="navimage" alt="buyerIcon" style={{ marginTop: "5px" }} src="/images/notificationactive.png" />,
          image: <img className="navimage" alt="buyerIcon" style={{ marginTop: "5px" }} src="/images/notification.png" />,
          counting: notification_count !== null && notification_count !== 0 && <div className={notification_count >= 100 ? "imagesize3" : "imagesize"}>{notification_count}</div>,
        },

        {
          id: 6,
          name: "Messages",
          path: "/messages",
          activeimage: <img className="navimage" alt="buyerIcon" style={{ marginTop: "5px" }} src="/images/messageactive.png" />,
          image: <img className="navimage" alt="buyerIcon" style={{ marginTop: "5px" }} src="/images/messageIcon.png" />,
          msgCount: message_count !== null && <div className={notification_count >= 100 ? "imagesize3" : "imagesize"}>{message_count}</div>,
        },
        {
          id: 7,
          name: "Find Agents",
          path: "/findagent",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/findagentactive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/findagent.png" />
        },
        {
          id: 8,
          name: "Tours",
          path: "/agenttour",
          activeimage: <img className="navimage" alt="buyerIcon" style={{ marginTop: "8px" }} src="/images/touractive.png" />,
          image: <img className="navimage" alt="buyerIcon" style={{ marginTop: "8px" }} src="/images/tourIcon.png" />
        },
           {
          id: 10,
          name: "Saved Searches",
          path: "/SavedSearch",
          activeimage: <img className="navimage" alt="buyerIcon" style={{ marginTop: "6px" }} src="/images/SavedSearchActive.png" />,
          image: <img className="navimage" alt="buyerIcon" style={{ marginTop: "6px" }} src="/images/SavedSearch.png" />
        },
        // {
        //   id: 10,
        //   name: "Network",
        //   path: "/network",
        //   activeimage: <img className="navimage" alt="buyerIcon" style={{ marginTop: "6px" }} src="/images/networkactive.png" />,
        //   image: <img className="navimage" alt="buyerIcon" style={{ marginTop: "6px" }} src="/images/network.png" />
        // },

      ]
      setMenu(menuArr)
    } else if (userType === "user") {

      var menuArr = [
        {
          id: 2,
          name: "Owned Properties",
          path: "/ownedproperties",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/ownedpropertyactive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/ownedproperty.png" />
        },
        {
          id: 3,
          name: "Listed Properties",
          path: "/listproperty",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/listpropertyactive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/listproperty.png" />
        },
        {
          id: 4,
          name: "Interested Properties",
          path: "/interestedproperty",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/intrestedpropertyactive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/intrestedproperty.png" />
        },
        {
          id: 5,
          name: 'Notifications',
          path: "/notificationSeller",
          activeimage: <img className="navimage" alt="buyerIcon" style={{ marginTop: "5px" }} src="/images/notificationactive.png" />,
          image: <img className="navimage" alt="buyerIcon" style={{ marginTop: "5px" }} src="/images/notification.png" />,
          counting: notification_count !== null && notification_count !== 0 && <div className={notification_count >= 100 ? "imagesize3" : "imagesize"}>{notification_count}</div>,
        },
        {
          id: 6,
          name: "My Agents",
          path: "/myAgent",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/myagentactive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/myagent.png" />

        },
        {
          id: 7,
          name: "Find an Agent",
          path: "/findagent",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/findagentactive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/findagent.png" />
        },
        {
          id: 8,
          name: "Tours",
          path: "/tours",
          activeimage: <img className="navimage" alt="buyerIcon" style={{ marginTop: "8px" }} src="/images/touractive.png" />,
          image: <img className="navimage" alt="buyerIcon" style={{ marginTop: "8px" }} src="/images/tourIcon.png" />,

        },
        {
          id: 9,
          name: "Messages",
          path: "/messages",
          activeimage: <img className="navimage" alt="buyerIcon" src="/images/messageactive.png" />,
          image: <img className="navimage" alt="buyerIcon" src="/images/messageIcon.png" />,
          msgCount: message_count !== null && <div className={notification_count >= 100 ? "imagesize3" : "imagesize"}>{message_count}</div>,

        },
          {
          id: 10,
          name: "Saved Searches",
          path: "/SavedSearch",
          activeimage: <img className="navimage" alt="buyerIcon" style={{ marginTop: "6px" }} src="/images/SavedSearchActive.png" />,
          image: <img className="navimage" alt="buyerIcon" style={{ marginTop: "6px" }} src="/images/SavedSearch.png" />
        },

      ]
      setMenu(menuArr)
    }
  }, [notification_count, message_count])

  const [profile, setProfile] = useState("");


  useEffect(() => {
    async function fetchData() {
      // await listingApis();
      async function init() {
        let user = {
          id: userDetails?._id ? userDetails._id : "",
          name: userDetails?.name ? userDetails.name : "",
          image: hanldeImage(userDetails?.img ?? "")
        }
        const chatClient = StreamChat.getInstance(apikey);
        chatClient.on(event => {
          if (event.total_unread_count !== undefined) {
            dispatch(getMessageCount(event.total_unread_count))
            // setMessageCount(event.total_unread_count)
          }
        });
        await chatClient.connectUser(user, chatClient.devToken(user.id));
        dispatch(chattingUserData(chatClient))
        setClient(chatClient);
      }
      init();
      if (client) return () => client.disconnectUser();
    }
    fetchData()

  }, [])
  // const listingApis = async () => {
  //   let firebaseUid = JSON.parse(localStorage.getItem('userInfo'))
  //   await userDetailInfo(firebaseUid.uid)
  //     .then((res) => {
  //       console.log('listingApis res', res)
  //       if (res.status === 200) {
  //         dispatch(userResponse(res.data.data));
  //       }
  //     })
  //   // await profileView(firebaseUid.uid).then(async (res) => {
  //   //   setProfile(res.data.data)
  //   // }).catch((error) => {

  //   // })
  // }

  useEffect(() => {
    // console.log((window.location.pathname).split("/")[1])
    setActiveRoute((window.location.pathname).split("/")[1])
  }, [navigate])

  const [showInactive, setShowInactive] = useState(false)
  const toggleInactive = () => setShowInactive(p => !p)
  const [messages, setMessages] = useState("")
  // const [success, setSucess] = useState(false);
  // const toggleSuccess = () => setSucess(p => !p);
  const [active, inActive] = useState(userDetails?.online_status ? userDetails.online_status : "")

  const handleStatus = async () => {
    let uid = userDetails.uid
    let getParams = {
      online_status: !active
    }
    profileUpdate(uid, getParams).then(res => {
      setMessages(res.data.message)
      if (res.status === 200) {
        userDetailInfo(uid)
          .then((res) => {
            localStorage.setItem('userId', res.data.data._id)
            if (res.status === 200) {
              dispatch(userResponse(res.data.data));
              inActive(res.data.data.online_status)
              if (userDetails.online_status !== false) { toggleInactive() }
            }
          })
        // toggleSuccess()
      }
      // else {
      // toggleSuccess()
      // }
    })
  }

  return (
    <>
      <div className="col-xl-2  col-md-2 col-sm-2" style={{ background: "#F2F2F2" }}>
        <InactiveProfilePopup showInactive={showInactive} toggleInactive={toggleInactive} messages={messages} handleFunction={handleStatus} status={active} />
        <div>
          <nav>
            {/* {userType === "agent" ? (
              <Link to="/">
                <div style={{ padding: "25px 40px", margin: "-21px 0 0 0" }} className={styles.NavbarDiv}>
                  <img src={logo} alt="logo" className={styles.NavbarImageDiv} />
                </div>
              </Link>
            ) : null} */}
            <div
              className="nav flex-column nav-pills py-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"

            >
              {menu.map((item, index) => (
                <div key={index} onClick={async () => { await navigate(item.path) }} role="button" tabIndex="0" className="nav-links" aria-hidden style={
                  activeRoute === item.path.replace(/\//gi, '') ?
                    { background: "#fff", color: "#0490fb", padding: "10px 20px", borderRadius: "30px", textDecoration: "none", boxShadow: "1px 2px 3px 2px lightgrey", } : null}>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ fontSize: "20px", padding: "0px 9px 0 10px", display: "flex", alignItems: "center" }} className={styles.sidebarIcon}>
                      {item.path.replace(/\//gi, '') === activeRoute ? item.activeimage : item.image}
                    </span>
                    <div style={{ color: item.path.replace(/\//gi, '') === activeRoute ? "rgb(4, 144, 251)" : "gray", }} className={styles.sidebarName}>
                      {item.name}

                    </div>
                    {item.path.replace(/\//gi, '') !== activeRoute && item.counting !== undefined &&
                      <>
                        {notification_count !== null !== 0 &&
                          <div className="notificationPiont">
                            {notification_count !== null !== 0 &&
                              <p style={{ marginBottom: "none" }}>{item.counting}</p>
                            }
                          </div>}
                      </>
                    }
                    {item.path.replace(/\//gi, '') !== activeRoute && item.msgCount !== undefined &&
                      <div className="notificationPiont">
                        {message_count !== 0 &&
                          <p >{item.msgCount}</p>
                        }
                      </div>
                    }
                  </div>

                </div>
              ))}
            </div>
            {/* {userType === "agent" ? (
              <div style={{ marginTop: "3 px" }}>
                <div className="sidePadding" style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                  <div style={{ padding: "0px 7px" }}>
                    {userDetails.img ?
                      <img alt="no_image" src={process.env.API_IMAGE_URL + userDetails.img} style={{ borderRadius: "7px", width: "50px", height: "50px" }} crossOrigin="anonymous" />
                      :
                      <div style={{ width: '88px', height: '88px' }}>
                        <AvatarImage fontSize={'40px'} data={userDetails.name ? userDetails.name : ''} />
                      </div>
                    }
                  </div>
                  <>
                    <div className="sideProfile">
                      <div className={styles.sidebarlogout}>
                        <div style={{ padding: "5px 0px" }} className="text-capitalize profileNameSide">{userDetails.name
                        }</div>
                        <div className="position-relative">
                          <Icon icon="akar-icons:chevron-down" className={styles.dropIcon} onClick={showProfilePopup} />
                          {showProfile ? (
                            <div className='col-lg-12 col-xl-12 col-md-12 m-0 logout-dropdown' id="logout-dropdown" onClick={handleLogoutFirebase} aria-hidden role="button" tabIndex="0">
                              <Link to="/" className={`userLogout ${styles.Link}`} onClick={handleLogoutFirebase} >Logout</Link>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <Link to="/viewprofile">
                        <div className="viewProfile" style={{ color: "#0d6efd", cursor: "pointer", }}>View Profile <i className="fa fa-eye"></i>
                        </div>
                      </Link>
                    </div>
                  </>

                </div>
              </div>
            ) : null} */}

            {/* {userType === "agent" &&
              <div className="d-flex activeStatus">
                <p className="onlineText" id="onlineText1">Online Status</p>
                <label className="switch activeSwitch">
                  {userDetails.online_status !== false ?
                    <input type="checkbox" checked={active} onClick={toggleInactive} />
                    : <input type="checkbox" checked={active} onClick={handleStatus} />
                  }
                  <span className="slider round"></span>
                </label>
              </div>} */}
          </nav>
        </div>
      </div>
    </>
  )
}
