import React, { useEffect } from "react";
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
// import GoogleAnalytics from "../pages/GoogleAnalytics2"
import { useDispatch, useSelector } from "react-redux";
import propImage from "../../static/images/EditGroup1.png"

function Seo({ description, lang, meta, title, link }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
           
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const userDetails = useSelector(state => state.user.userDetail)

  React.useEffect(() => {
    // react-helmet does not prepend so need to do it manually
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'google-site-verification');
    meta.setAttribute('content', '1K9U183nKTu2mpBtAmw0Pb58FoTtYx-XiY_tXOXzEtI');
    const head = document.querySelector('head');
    head.insertBefore(meta, head.firstChild);
  }, []);



  return (
    <div>
      <link rel="canonical" href="https://www.gokeywe.com" />
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`GoKeyWe: Off-market listings and top-rated agents`}
        description={`Access Off Market and Broker Exclusives through the premier Agent and Broker-Driven site for property listings, services and AI-driven tools. This is where REAL agents connect with REAL buyers and sellers.`}


        meta={[
          {
            name: `description`,
            content: `Access Off Market and Broker Exclusives through the premier Agent and Broker-Driven site for property listings, services and AI-driven tools. This is where REAL agents connect with REAL buyers and sellers.`
          },
          {
            property: `og:locale`,
            content: `en_US`
          },
          {
            property: `og:title`,
            content: `GoKeyWe: Off-market listings and top-rated agents`
          },
          {
            property: `og:description`,
            content: `Access Off Market and Broker Exclusives through the premier Agent and Broker-Driven site for property listings, services and AI-driven tools. This is where REAL agents connect with REAL buyers and sellers.`
          },
          {
            property: `og:url`,
            content: `https://gokeywe.com/`
          },
          {
            property: `og:site_name`,
            content: `Go KeyWe`
          },
          {
            property: `og:image`,
            content: `https://gokeywe.com/${propImage}`
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`
          },
          {
            name: `twitter:url`,
            content: `https://gokeywe.com/`
          },
          {
            name: `twitter:image`,
            content: `https://gokeywe.com/pro/assets/img/home/home-section2.png`
          },
          {
            name: `twitter:title`,
            content: `GoKeyWe: Off-market listings and top-rated agents`
          },
          {
            name: `twitter:description`,
            content: `Access Off Market and Broker Exclusives through the premier Agent and Broker-Driven site for property listings, services and AI-driven tools. This is where REAL agents connect with REAL buyers and sellers.`
          },
          // {
          //   name: `google-site-verification`,
          //   content: `1K9U183nKTu2mpBtAmw0Pb58FoTtYx-XiY_tXOXzEtI`,
          // },
        ].concat(meta)}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify([
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "KeyWe, Inc.",
              "url": "https://gokeywe.com/"
            },
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "KeyWe, Inc.",
              "url": "https://gokeywe.com/",
              "logo": "https://gokeywe.com/images/keyweUpdatedlogo.png"
            },
            {
              "@context": "https://schema.org",
              "@type": "SearchAction",
              "target": "https:gokeywe.com/?s={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          ])}
        </script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              "name": "KeyWe, Inc.",
              "description": "Access Off Market and Broker Exclusives through the premier Agent and Broker-Driven site for property listings, services and AI-driven tools. This is where REAL agents connect with REAL buyers and sellers..",
              "logo": "https://gokeywe.com/pro/assets/img/logo.svg",
              "url": "https://gokeywe.com",
              "telephone": "408-877-6510",
              "email": "info@keywe.com",
              "sameAs": [
                "https://twitter.com/go_keywe",
                "https://www.linkedin.com/company/keywe-inc/",
                "https://www.facebook.com/people/Go-KeyWe/100091772771110/",
                "https://www.instagram.com/go_keywe/"
              ],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "16185 Los Gatos Blvd #205",
                "addressLocality": "Los Gatos",
                "postalCode": "95032",
                "addressCountry": "United States"
              }
            }
          `}
        </script>
      </Helmet>
      <Helmet>
        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-250923000-1">
        </script>
        <script>
          {` window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-250923000-1');
          `}
        </script>
      </Helmet>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-60ER13GP5Y"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
      
          gtag('config', 'G-60ER13GP5Y');
         `}
        </script>
      </Helmet>

      <Helmet>
        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11226811000">
        </script>
        <script>
          {` window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'AW-11226811000');
          `}
        </script>
      </Helmet>
      <Helmet>
        <script>
          {`(function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
              'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-5DCZ5QT');`}</script>
      </Helmet>
    </div>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
