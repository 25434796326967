import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import '../styles/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from "react-redux";

import Sidebar from "./Sidebar";

export default function Layout({ children }) {
    const [storage, setStorage] = useState(false);
    const [userType, setUserType] = useState("");
    const userDetails = useSelector(state => state.user.userDetail)

    useEffect(() => {
        const storedData = localStorage.getItem("userInfo");
        if (!storedData) {
            setStorage(false);
        } else {
            setStorage(true);
            let loggedUserType = JSON.parse(localStorage.getItem('userInfo')).userType;
            setUserType(loggedUserType);
        }
    }, []);


    // console.log("storage", storage)
    // console.log("userType", userType)
    // console.log("loggedUserType", userDetails);
    return (
        <div className="container-fluid" style={{ background: "#FFFFFF", padding: '0px' }}>
            {userType === "agent" ? <Navbar isLogin={storage} /> : <Navbar isLogin={storage} />}
            <div className="row m-0" style={{ minHeight: "100vh" }}>
                {storage ? <Sidebar userType={userType} /> : null}
                <div className="col-xl-10 col-md-10 col-sm-10" style={{ background: "#F2F2F2" }} >
                    {children}
                </div>
            </div>
            <Footer isLogin={storage} />
        </div>
    )
}



