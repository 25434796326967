import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';

export default function TeamPopup(props) {
    const { showInactive, toggleInactive, status } = props;

    return (
        <div>
            <Modal show={showInactive} onHide={toggleInactive} >
                <Modal.Header closeButton className="team-popup-margin teampopup-title"  >
                    <Modal.Title className='form-field-label form-label-team '><h5 className='teamText'>Alert</h5></Modal.Title>
                </Modal.Header>
                {status === true ?
                    <Modal.Body className=" pb-0" >

                        <div className='row'>
                            <h6 className={style1.photosendtext}>Do you really want to change your status to offline?</h6>
                        </div>

                        <div className='d-flex justify-content-center mt-3 agentactiveStatus'>
                            <Button variant="primary" onClick={() => props.handleFunction()} className={style1.photosendbut1}>
                                Yes
                            </Button>
                            <Button variant="primary" onClick={toggleInactive} className={style1.photosendbut1}>
                                No
                            </Button>
                        </div>
                    </Modal.Body>
                    : null}
                {(window.location.pathname === "/signin" && status === false) &&
                    <Modal.Body className=" pb-4 pt-4" >

                        <div className='row'>
                            <h6 className={style1.photosendtext1} >Your status is offline.</h6>

                        </div>
                    </Modal.Body>
                }
                <Modal.Footer className="team-popup-margin teampop-footer" >

                </Modal.Footer>
            </Modal>
        </div>
    )
}
